<template>
  <div>
    <v-form data-vv-scope="attributes" :class="{ loading: isLoading }">
      <v-layout class="row wrap mb-4">
        <v-flex xs12 class="mb-4">
          <v-expansion-panel :value="0">
            <v-expansion-panel-content>
              <template slot="header">
                <v-layout row>
                  <v-flex xs12>{{ plugin.name }}</v-flex>
                </v-layout>
              </template>
              <v-card class="px-2 py-1" color="grey lighten-5">
                <draggable
                  v-model="attributes"
                  :group="{ name: 'attributes' }"
                  :handle="'.draggable-handle'"
                  @change="sortAttributes"
                  class="draggable"
                >
                  <div
                    v-for="(attribute, attributeIndex) in attributes"
                    :key="'attribute-' + attributeIndex"
                    class="my-1"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-content>
                        <template slot="header">
                          <v-layout class="row no-wrap align-items-center">
                            <v-flex class="xs12 attribute-name">
                              <v-layout row class="align-center">
                                <font-awesome-icon
                                  icon="grip-vertical"
                                  class="sw-accent draggable-handle mr-3"
                                />
                                <v-flex class="sw-h5 font-weight-light"
                                  >{{ attribute.name }}
                                  <div
                                    v-if="attribute.description"
                                    class="caption grey--text"
                                  >
                                    {{ attribute.description }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <div class="mr-3">
                              <v-tooltip top>
                                <v-btn
                                  slot="activator"
                                  icon
                                  class="my-0 mr-0"
                                  @click.stop="
                                    openUserAttributeEditDialog(attribute.id)
                                  "
                                >
                                  <v-icon color="grey" small>edit</v-icon>
                                </v-btn>
                                <span>{{ $t("edit") }}</span>
                              </v-tooltip>
                            </div>
                          </v-layout>
                        </template>
                        <v-card class="pa-2" color="grey lighten-5">
                          <template
                            v-if="
                              attribute.details && attribute.details.description
                            "
                          >
                            <div
                              v-html="attribute.details.description"
                              class="sw-content-typography pt-2 pb-4 clearfix"
                            ></div>
                          </template>
                          <div
                            v-if="
                              attribute.type === 'options' &&
                              attribute.is_collection
                            "
                          >
                            <div
                              v-for="(option, optionIndex) in attribute.options"
                              :key="`option-${optionIndex}`"
                            >
                              <v-checkbox
                                :label="option.name"
                                hide-details
                                class="shrink mt-0"
                                disabled
                              />
                            </div>
                            <div v-if="!attribute || !attribute.options[0]">
                              {{ $t("no-options-added-yet") }}!
                            </div>
                          </div>
                          <div
                            v-if="
                              attribute.type === 'options' &&
                              !attribute.is_collection
                            "
                          >
                            <v-radio-group column>
                              <template
                                v-for="(
                                  option, optionIndex
                                ) in attribute.options"
                              >
                                <v-radio
                                  :key="`option-${optionIndex}`"
                                  :label="option.name"
                                  hide-details
                                  disabled
                                />
                              </template>
                            </v-radio-group>
                            <div v-if="!attribute.options.length">
                              {{ $t("no-options-added-yet") }}!
                            </div>
                          </div>
                          <div v-if="attribute.type === 'varchar'">
                            <v-text-field
                              :placeholder="$t('textFieldPlaceholder')"
                              hide-details
                              solo
                              disabled
                            />
                          </div>
                          <div v-if="attribute.type === 'text'">
                            <v-textarea
                              :placeholder="$t('textFieldPlaceholder')"
                              no-resize
                              hide-details
                              solo
                              disabled
                            />
                          </div>
                          <div v-if="attribute.type === 'datetime'">
                            <v-text-field
                              :placeholder="'DD.MM.YYYY'"
                              hide-details
                              solo
                              disabled
                            />
                          </div>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </div>
                </draggable>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <div v-if="errors && errors.collect('attributes.attributes').length">
            <ErrorMessages
              :error-messages="errors.collect('attributes.attributes')"
            />
          </div>
        </v-flex>
        <v-flex xs12 class="text-center">
          <v-btn
            fab
            large
            class="sw-accent-bg sw-on-accent"
            @click="openUserAttributeCreateDialog()"
          >
            <v-icon dark>add</v-icon>
          </v-btn>
          <div class="caption">
            {{ $t("group_user_attribute_wizard.add_attribute_btn_text") }}
          </div>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <v-expansion-panel>
            <v-expansion-panel-content>
              <template slot="header">
                <v-layout row>
                  <v-flex xs12>{{
                    $t(
                      "group_user_attribute_wizard.attributes_without_plugin_title",
                    )
                  }}</v-flex>
                </v-layout>
              </template>
              <v-card class="px-2 py-1" color="grey lighten-5">
                <draggable
                  v-model="attributesWithoutPlugin"
                  :group="{ name: 'attributes' }"
                  @change="uncategorize"
                  class="draggable"
                >
                  <div
                    v-for="(attribute, i) in attributesWithoutPlugin"
                    :key="`attribute-${i}`"
                    class="my-1"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-content>
                        <template slot="header">
                          <v-layout class="row no-wrap align-items-center">
                            <v-flex class="xs12 attribute-name">
                              <v-layout row class="align-center">
                                <font-awesome-icon
                                  icon="grip-vertical"
                                  class="sw-accent draggable-handle mr-3"
                                />
                                <v-flex class="sw-h5 font-weight-light"
                                  >{{ attribute.name }}
                                  <div
                                    v-if="attribute.description"
                                    class="caption grey--text"
                                  >
                                    {{ attribute.description }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <div class="mr-3">
                              <v-tooltip top>
                                <v-btn
                                  slot="activator"
                                  icon
                                  class="my-0 mr-0"
                                  @click.stop="
                                    openUserAttributeEditDialog(attribute.id)
                                  "
                                >
                                  <v-icon color="grey" small>edit</v-icon>
                                </v-btn>
                                <span>{{ $t("edit") }}</span>
                              </v-tooltip>
                            </div>
                          </v-layout>
                        </template>
                        <v-card class="pa-2" color="grey lighten-5">
                          <template
                            v-if="
                              attribute.details && attribute.details.description
                            "
                          >
                            <div
                              v-html="attribute.details.description"
                              class="sw-content-typography pt-2 pb-4 clearfix"
                            ></div>
                          </template>
                          <div
                            v-if="
                              attribute.type === 'options' &&
                              attribute.is_collection
                            "
                          >
                            <div
                              v-for="(option, optionIndex) in attribute.options"
                              :key="`option-${optionIndex}`"
                            >
                              <v-checkbox
                                :label="option.name"
                                hide-details
                                class="shrink mt-0"
                                disabled
                              />
                            </div>
                            <div v-if="!attribute || !attribute.options[0]">
                              {{ $t("no-options-added-yet") }}!
                            </div>
                          </div>
                          <div
                            v-if="
                              attribute.type === 'options' &&
                              !attribute.is_collection
                            "
                          >
                            <v-radio-group column>
                              <template
                                v-for="(
                                  option, optionIndex
                                ) in attribute.options"
                              >
                                <v-radio
                                  :key="`option-${optionIndex}`"
                                  :label="option.name"
                                  hide-details
                                  disabled
                                />
                              </template>
                            </v-radio-group>
                            <div v-if="!attribute.options.length">
                              {{ $t("no-options-added-yet") }}!
                            </div>
                          </div>
                          <div v-if="attribute.type === 'varchar'">
                            <v-text-field
                              :placeholder="$t('textFieldPlaceholder')"
                              hide-details
                              solo
                              disabled
                            />
                          </div>
                          <div v-if="attribute.type === 'text'">
                            <v-textarea
                              :placeholder="$t('textFieldPlaceholder')"
                              no-resize
                              hide-details
                              solo
                              disabled
                            />
                          </div>
                          <div v-if="attribute.type === 'datetime'">
                            <v-text-field
                              :placeholder="'DD.MM.YYYY'"
                              hide-details
                              solo
                              disabled
                            />
                          </div>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </div>
                </draggable>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-flex>
      </v-layout>
    </v-form>

    <Attributes
      v-if="plugin.id"
      :groupId="groupId"
      :groupPluginId="plugin.id"
      ref="attributeRef"
      @attribute-created="atttributeListSort"
      @attribute-updated="atttributeList"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Attributes from "@/components/GroupUserAttributeWizard/GuestAttributes/Attributes.vue";

export default {
  inject: ["parentValidator"],
  props: {
    groupId: {
      type: [String, Number],
    },
  },
  data: () => ({
    isLoading: false,
    isProcessing: false,
  }),
  computed: {
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    plugin: {
      get() {
        return this.$store.getters.groupUserAttributeWizardPlugin;
      },
      set(val) {
        this.$store.dispatch("setGroupUserAttributeWizardPlugin", val);
      },
    },
    attributes: {
      get() {
        return this.$store.getters.groupUserAttributeWizardAttributes;
      },
      set(val) {
        this.$store.dispatch("setGroupUserAttributeWizardAttributes", val);
      },
    },
    attributesWithoutPlugin: {
      get() {
        return this.$store.getters
          .groupUserAttributeWizardAttributesWithoutPlugin;
      },
      set(val) {
        this.$store.dispatch(
          "setGroupUserAttributeWizardAttributesWithoutPlugin",
          val,
        );
      },
    },
  },
  components: {
    draggable,
    Attributes,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    async uncategorize(node) {
      if (!node || !node.added) return;

      try {
        const specs = [
          this.groupId,
          node.added.element.id,
          {
            group_plugin_id: null,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.update(...specs);

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async atttributeListSilent() {
      this.errors.clear();

      const params = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          lang: this.appContentLanguage,
          per_page: 500,
        },
      ];

      const response = await this.$api.groupUserAttributes.list(...params);

      const attributes = response.data.data.filter((el) => el.enabled);

      await this.$store.dispatch(
        "setGroupUserAttributeWizardAttributes",
        attributes,
      );
    },
    async atttributeWithoutPluginListSilent() {
      this.errors.clear();

      const params = [
        this.groupId,
        {
          group_plugin_id: "",
          lang: this.appContentLanguage,
          per_page: 500,
        },
      ];

      const response = await this.$api.groupUserAttributes.list(...params);

      const attributes = response.data.data.filter((el) => el.enabled);

      await this.$store.dispatch(
        "setGroupUserAttributeWizardAttributesWithoutPlugin",
        attributes,
      );
    },
    async atttributeList() {
      try {
        this.isLoading = true;

        await this.atttributeListSilent();
        await this.atttributeWithoutPluginListSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async atttributeListSort() {
      try {
        this.isLoading = true;

        await this.atttributeListSilent();
        await this.sortAttributes();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async openUserAttributeCreateDialog() {
      if (!this.$refs.attributeRef) return;
      this.$refs.attributeRef.openUserAttributeCreateDialog();
    },
    async openUserAttributeEditDialog(userAttributeId) {
      if (!this.$refs.attributeRef || !userAttributeId) {
        return;
      }
      this.$refs.attributeRef.openUserAttributeEditDialog(userAttributeId);
    },
    async sortAttributes(node) {
      if (this.isProcessing) return;

      this.isProcessing = true;

      if (node && node.added) {
        try {
          const specs = [
            this.groupId,
            node.added.element.id,
            {
              group_plugin_id: this.plugin.id,
            },
          ];

          this.isLoading = true;

          await this.$api.groupUserAttributes.update(...specs);

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
          }
        }
      }

      try {
        const specs = [
          this.groupId,
          { new_order: this.attributes.map((el) => el.id).toString() },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.sort(...specs);

        this.isLoading = false;
        this.isProcessing = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.isProcessing = false;
          this.errorMessageShow(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attribute-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sortable-ghost {
  opacity: 0;
}

.loading {
  pointer-events: none;
  opacity: 0.5;
}

.draggable {
  min-height: 69px;
}
</style>
