<template>
  <div>
    <v-layout row wrap class="text-center">
      <v-flex xs12 class="mb-5">
        <div class="sw-h3 font-weight-light">
          {{ $t("group_user_attribute_wizard.guest_attributes_created") }}
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
