<template>
  <div>
    <v-dialog v-model="dialog" scrollable width="820" persistent>
      <v-card v-if="dialog" class="border-radius">
        <v-card-actions>
          <v-spacer />
          <v-btn
            icon
            @click="
              dialog = false;
              enableMultipleOptions = false;
            "
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-card-actions>
        <div class="mb-3 sw-h4 text-center">
          {{ $t("group_user_attribute_wizard.attribute_dialog_title") }}
        </div>
        <v-card-text class="pt-0">
          <div class="pb-2">
            <v-layout class="row wrap mb-3">
              <v-flex xs12>
                <v-text-field
                  v-model="model.name"
                  :error-messages="errors.collect('name')"
                  v-validate.disable="'required|max:150'"
                  data-vv-name="name"
                  :label="
                    $t('group_user_attribute_wizard.attribute_name_label')
                  "
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="model.description"
                  :label="
                    $t('group_user_attribute_wizard.internal_description_label')
                  "
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="attributeType"
                  :items="attributeTypes"
                  :error-messages="errors.collect('type')"
                  v-validate.disable="'required'"
                  data-vv-name="type"
                  item-text="name"
                  item-value="id"
                  return-object
                  :label="
                    $t('group_user_attribute_wizard.attribute_type_label')
                  "
                  :disabled="model && model.id ? true : false"
                >
                  <template slot="item" slot-scope="data">
                    <v-layout class="row slot-item align-center">
                      <v-flex class="xs6">
                        <span class="body-2">{{ data.item.name }}</span>
                      </v-flex>
                      <v-flex class="xs6 text-right">
                        <span class="sw-caption text-capitalize">{{
                          data.item.description
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                v-if="attributeType && attributeType.type === 'options'"
                xs12
              >
                <v-card flat>
                  <v-card-text class="pa-0">
                    <v-layout row wrap>
                      <v-flex xs12 mb-3 pb-2>
                        <tooltip
                          :name="
                            enableMultipleOptions
                              ? 'RegistrationQuestionOptionsMultiple'
                              : 'RegistrationQuestionOptions'
                          "
                        >
                          <strong>{{ $t("options") }}</strong>
                        </tooltip>
                      </v-flex>
                      <v-flex xs12 v-show="!enableMultipleOptions">
                        <draggable v-model="model.options" :handle="'.handle'">
                          <div
                            v-for="(option, i) in model.options"
                            :key="`option-${i}`"
                          >
                            <v-layout row nowrap class="align-items-center">
                              <div
                                v-if="model.options.length > 1"
                                class="handle pr-3"
                              >
                                <font-awesome-icon
                                  icon="grip-vertical"
                                  class="handle sw-accent"
                                />
                              </div>
                              <v-flex xs12 class="pr-2">
                                <v-text-field
                                  v-model="option.name"
                                  :error-messages="
                                    errors.collect('option_' + i)
                                  "
                                  v-validate.disable="'required'"
                                  :data-vv-name="'option_' + i"
                                  :data-vv-as="$tc('option', 2)"
                                  :label="$tc('option', 1)"
                                  autofocus
                                  @keyup.enter.native="addNewOption"
                                />
                              </v-flex>
                              <div v-if="model.options.length > 1">
                                <v-btn
                                  v-if="option.editable"
                                  icon
                                  class="mr-0"
                                  @click="removeOption(i)"
                                >
                                  <font-awesome-icon
                                    class="sw-accent"
                                    icon="trash"
                                  />
                                </v-btn>
                              </div>
                            </v-layout>
                          </div>
                        </draggable>
                      </v-flex>
                      <v-flex xs12 v-show="enableMultipleOptions">
                        <v-textarea
                          v-model="multipleOptions"
                          :placeholder="
                            $t(
                              'group_user_attribute_wizard.insert_text_option_per_line',
                            )
                          "
                          solo
                          rows="10"
                          no-resize
                          ref="multiple-option"
                        >
                        </v-textarea>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout>
                          <template v-if="!enableMultipleOptions">
                            <v-btn
                              round
                              class="mx-0 sw-secondary-bg sw-on-secondary text-none"
                              @click="addNewOption"
                              >{{ $t("new-option") }}</v-btn
                            >
                            <tooltip name="RegistrationQuestionOptionsMultiple">
                              <v-btn
                                round
                                class="mr-0 ml-3 sw-secondary-bg sw-on-secondary text-none"
                                @click="addMultipleOptions"
                                >{{
                                  $t(
                                    "group_user_attribute_wizard.add_multiple_options",
                                  )
                                }}</v-btn
                              >
                            </tooltip>
                          </template>
                          <template v-if="enableMultipleOptions">
                            <v-btn
                              round
                              class="mx-0 sw-secondary-bg sw-on-secondary text-none"
                              @click="enableMultipleOptions = false"
                              >{{ $t("common.cancel") }}</v-btn
                            >
                            <v-btn
                              round
                              class="mr-0 ml-3 sw-secondary-bg sw-on-secondary text-none"
                              :disabled="
                                !multipleOptions || !multipleOptions.length
                              "
                              @click="addOptions"
                              >{{
                                $t("group_user_attribute_wizard.add_options")
                              }}</v-btn
                            >
                          </template>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <div class="pa-3 text-xs-center">
          <v-btn
            v-if="model.id"
            round
            class="white sw-primary text-none"
            @click="disableUserAttribute(model.id)"
            :loading="isLoading"
            >{{ $t("common.delete") }}</v-btn
          >
          <v-btn
            round
            class="white sw-primary text-none"
            @click="
              dialog = false;
              enableMultipleOptions = false;
            "
            >{{ $t("common.cancel") }}</v-btn
          >
          <v-btn
            v-if="!model.id"
            round
            class="sw-accent-bg sw-on-accent text-none"
            @click="createUserAttribute"
            :loading="isLoading"
            >{{ $t("common.create") }}</v-btn
          >
          <v-btn
            v-if="model.id"
            round
            class="sw-accent-bg sw-on-accent text-none"
            @click="updateUserAttribute"
            :loading="isLoading"
            >{{ $t("common.save") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <ConfirmationDialog ref="confirmationDialog" />
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "AttributesForm",
  components: {
    draggable,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    groupPluginId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    enableMultipleOptions: false,
    multipleOptions: null,
    model: {
      options: [],
    },
    datetimeFormat: null,
    attributeType: {},
    option: {},
  }),
  computed: {
    attributeTypes() {
      return [
        {
          id: 1,
          name: this.$t("varchar-label"),
          description: "",
          type: "varchar",
          is_collection: false,
        },
        {
          id: 2,
          name: this.$t("text-label"),
          description: "",
          type: "text",
          is_collection: false,
        },
        {
          id: 3,
          name: this.$t("single-select-label"),
          description: "",
          type: "options",
          is_collection: false,
        },
        {
          id: 4,
          name: this.$t("multi-select-label"),
          description: "",
          type: "options",
          is_collection: true,
        },
        {
          id: 5,
          name: this.$t("date-picker", {
            format: this.datetimeFormat,
          }),
          description: "",
          type: "datetime",
          is_collection: false,
        },
      ];
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  watch: {
    attributeType(val) {
      if (!this.model.id && val && val.type === "options") {
        this.model.options = [];
        this.addNewOption();
      }
    },
  },
  mounted() {
    this.getDateTimeFormat();
  },
  methods: {
    clearDetails() {
      this.errors.clear();

      this.model = {
        options: [],
      };
      this.attributeType = {};
    },
    openUserAttributeCreateDialog() {
      this.clearDetails();

      this.dialog = true;
    },
    openUserAttributeEditDialog(attributeId) {
      this.clearDetails();

      if (attributeId) {
        this.getGroupUserAttribute(attributeId);
      }

      this.dialog = true;
    },
    addNewOption() {
      this.model.options.push({
        name: null,
        value: null,
        editable: true,
      });
    },
    addOptions() {
      this.multipleOptions.split("\n").forEach((name, index) => {
        if (!name) return;

        const option = name.split(";");

        this.model.options.push({
          name: option[0],
          value: option[0],
          editable: true,
        });
      });

      this.enableMultipleOptions = false;
    },
    addMultipleOptions() {
      this.multipleOptions = null;
      this.enableMultipleOptions = true;

      if (this.enableMultipleOptions) {
        this.$nextTick(() => this.$refs["multiple-option"].focus());
      }
    },
    removeOption(index) {
      this.model.options.splice(index, 1);
    },
    editAttributeOption(optionIndex, option) {
      this.$refs.optionModal.open(optionIndex, option);
    },
    updateUserAttributeValue(optionIndex, option) {
      this.$set(this.model.options, optionIndex, option);
    },
    transformOptionsForSave(options) {
      if (!options) return;

      return options.map((option, i) => ({
        id: option.id,
        name: option.name,
        value: option.name,
        sort_order: i,
      }));
    },
    transformOptionsForEdit(options) {
      if (!options) return;

      return options.map((option) => ({
        id: option.id,
        name: option.name,
        value: option.name,
        editable: true,
      }));
    },
    async getGroupUserAttribute(attributeId) {
      if (!attributeId) return;

      try {
        const params = [
          this.groupId,
          attributeId,
          { lang: this.appContentLanguage },
        ];

        this.isLoading = true;

        const response = await this.$api.groupUserAttributes.get(...params);

        this.isLoading = false;

        const attribute = response.data.data || {};

        this.model = {
          id: attribute.id,
          name: attribute.name,
          description: attribute.description,
          type: attribute.type,
          options:
            attribute.options && attribute.options.length
              ? this.transformOptionsForEdit(attribute.options)
              : [],
          is_collection: attribute.is_collection,
          enabled: attribute.enabled,
        };

        this.attributeType = this.attributeTypes.find(
          (attrType) =>
            attrType.type === attribute.type &&
            attrType.is_collection === attribute.is_collection,
        );
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async createUserAttribute() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      try {
        const specs = [
          this.groupId,
          {
            name: this.model.name,
            description: this.model.description,
            type: this.attributeType.type,
            is_collection: this.attributeType.is_collection ? 1 : 0,
            options:
              this.attributeType.type === "options"
                ? this.transformOptionsForSave(this.model.options)
                : [],
            enabled: 1,
            group_plugin_id: this.groupPluginId,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.create(...specs);

        this.isLoading = false;
        this.dialog = false;
        this.enableMultipleOptions = false;

        this.$emit("attribute-created");

        this.$store.dispatch("addNotification", {
          message: this.$t("group_user_attribute_wizard.attribute_created"),
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async updateUserAttribute() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      try {
        const specs = [
          this.groupId,
          this.model.id,
          {
            name: this.model.name,
            description: this.model.description,
            options:
              this.model.type === "options"
                ? this.transformOptionsForSave(this.model.options)
                : [],
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.update(...specs);

        this.isLoading = false;

        this.dialog = false;
        this.enableMultipleOptions = false;

        this.$emit("attribute-updated");

        this.$store.dispatch("addNotification", {
          message: this.$t("group_user_attribute_wizard.attribute_updated"),
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async disableUserAttribute(attributeId) {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("group_user_attribute_wizard.attribute_delete_warning_message"),
      );

      if (!isConfirmed || !attributeId) return;

      try {
        const specs = [
          this.groupId,
          attributeId,
          {
            enabled: 0,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.update(...specs);

        this.isLoading = false;

        this.dialog = false;
        this.enableMultipleOptions = false;

        this.$emit("attribute-updated");

        this.$store.dispatch("addNotification", {
          message: this.$t("group_user_attribute_wizard.attribute_deleted"),
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async getDateTimeFormat() {
      try {
        const response = await this.$api.settings.get("global.date_format");

        this.datetimeFormat = response.data.data.value;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
  },
};
</script>

<style scoped>
.handle {
  cursor: grab;
}

.sortable-chosen {
  opacity: 0.4;
}

.zeropad /deep/ .v-toolbar__content {
  padding: 0;
}
</style>
