<template>
  <div>
    <v-form data-vv-scope="settings">
      <v-layout>
        <v-flex xs6>
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="mb-2 sw-h5">{{
              $t("group_user_attribute_wizard.plugin_name_label")
            }}</v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="plugin.name"
                v-validate.disable="'required|min:3'"
                data-vv-name="name"
                :data-vv-as="
                  $t('group_user_attribute_wizard.plugin_name_data_vv_as')
                "
                :placeholder="
                  $t('group_user_attribute_wizard.plugin_name_placeholder')
                "
                hide-details
              ></v-text-field>
              <ErrorMessages
                :error-messages="errors.collect('settings.name')"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
export default {
  inject: ["parentValidator"],
  computed: {
    plugin: {
      get() {
        return this.$store.getters.groupUserAttributeWizardPlugin;
      },
      set(val) {
        this.$store.dispatch("setGroupUserAttributeWizardPlugin", val);
      },
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
};
</script>

<style lang="scss" scoped></style>
