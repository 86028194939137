<template>
  <v-container class="general">
    <!-- Title -->
    <page-title
      :component="'GroupUsersAttributes'"
      :translatable-tooltip="
        $t('group_user_attribute_wizard.page_title_tooltip')
      "
    >
      <template v-slot:actions>
        <LanguageSwitcher
          v-if="activeStep !== 'done'"
          ref="languageSwitcher"
          @click="changeNext('stay')"
          :loading="isLoading"
        />
      </template>
    </page-title>
    <!-- Steps -->
    <AppStepperHeader v-model="stepper" :steps="steps" :loading="isLoading" />
    <v-window v-model="stepper" class="mb-5 no-transition">
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
        :class="{ loading: isLoading }"
      >
        <component :is="step.component" :group-id="groupId" />
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <v-btn
      v-if="plugin.id && !isTemplateGroup && activeStep !== 'done'"
      round
      large
      class="ml-0 mr-3 white sw-accent text-none"
      :loading="isLoading"
      @click="deletePlugin"
      >{{ $t("common.delete") }}</v-btn
    >
    <v-btn
      v-if="activeStep !== 'done'"
      round
      large
      class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
      :loading="isLoading"
      @click="changeNext()"
      >{{ $t("common.continue") }}</v-btn
    >
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import AppStepperHeader from "@/components/AppStepperHeader.vue";
import Settings from "@/components/GroupUserAttributeWizard/Settings.vue";
import GuestAttributes from "@/components/GroupUserAttributeWizard/GuestAttributes.vue";
import Done from "@/components/GroupUserAttributeWizard/Done.vue";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    stepper: 0,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    isTemplateGroup() {
      return this.$store.getters.activeGroup.is_template;
    },
    plugin() {
      return this.$store.getters.groupUserAttributeWizardPlugin;
    },
    attributes() {
      return this.$store.getters.groupUserAttributeWizardAttributes;
    },
    steps() {
      return [
        {
          name: "settings",
          title: this.$t("group_user_attribute_wizard.settings_step_title"),
          component: Settings,
          enabled: true,
        },
        {
          name: "attributes",
          title: this.$t("group_user_attribute_wizard.attributes_step_title"),
          component: GuestAttributes,
          enabled: true,
        },
        {
          name: "done",
          title: this.$t("group_user_attribute_wizard.done_step_title"),
          component: Done,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    activeStep() {
      return this.steps[this.stepper].name;
    },
  },
  components: {
    LanguageSwitcher,
    AppStepperHeader,
  },
  watch: {
    groupPluginId: {
      immediate: true,
      handler() {
        this.fetchAll();
      },
    },
    appContentLanguage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.fetchAll();
      },
    },
    activeStep: {
      immediate: true,
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  },
  methods: {
    stepClass(i) {
      return [
        "pa-3 text-center step",
        {
          complete: i < this.stepper,
          editable: i < this.stepper && this.activeStep !== "done",
          active: i === this.stepper,
        },
      ];
    },
    async changeNext(action) {
      this.errors.clear();

      if (this.activeStep === "settings") {
        const isValid = await this.$validator.validateAll("settings");

        if (!isValid) return;

        try {
          this.isLoading = true;

          if (!this.plugin.id) {
            await this.createPlugin();
          } else {
            await this.updatePlugin();
          }

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (this.activeStep === "attributes") {
        if (!this.attributes.length) {
          this.errors.add({
            msg: this.$t("group_user_attribute_wizard.no_attributes_error"),
            scope: "attributes",
            field: "attributes",
          });
        }

        if (this.errors && this.errors.items && this.errors.items.length) {
          return;
        }

        try {
          this.isLoading = true;

          await this.updatePlugin();
          await this.listAttributes();
          await this.listAttributesWithoutPlugin();

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (action !== "stay") {
        this.stepper += 1;
      } else {
        this.$refs.languageSwitcher.open();
      }
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        if (this.groupPluginId) {
          await this.getPlugin();
          await this.listAttributes();
          await this.listAttributesWithoutPlugin();
        } else {
          this.$store.dispatch("setGroupUserAttributeWizardPlugin", {});
          this.$store.dispatch("setGroupUserAttributeWizardAttributes", []);
          this.$store.dispatch(
            "setGroupUserAttributeWizardAttributesWithoutPlugin",
            [],
          );
        }

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async createPlugin() {
      const specs = [
        this.groupId,
        {
          name: this.plugin.name,
          prefix: "guestattributes",
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupPlugins.create(...specs);

      if (!response) return;

      await this.$store.dispatch(
        "setGroupUserAttributeWizardPlugin",
        response.data.data,
      );

      this.$router.push({
        name: "group_user_attributes_edit",
        params: {
          group_id: this.groupId,
          group_plugin_id: response.data.data.id,
        },
      });
    },
    async getPlugin() {
      const params = [
        this.groupId,
        this.groupPluginId,
        {
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupPlugins.get(...params);

      await this.$store.dispatch(
        "setGroupUserAttributeWizardPlugin",
        response.data.data,
      );
    },
    async updatePlugin() {
      const specs = [
        this.groupId,
        this.plugin.id,
        {
          name: this.plugin.name,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupPlugins.update(...specs);

      if (!response) return;

      await this.$store.dispatch(
        "setGroupUserAttributeWizardPlugin",
        response.data.data,
      );
    },
    async deletePlugin() {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("group_user_attribute_wizard.delete_confirmation_text"),
        this.$t("common.no"),
        this.$t("common.yes"),
      );

      if (!isConfirmed) return;

      this.isLoading = true;

      try {
        const params = [this.groupId, this.plugin.id];

        await this.$api.groupPlugins.delete(...params);

        this.$router.push({
          name: "group_user_attributes",
          params: {
            group_id: this.groupId,
          },
        });

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async listAttributes() {
      this.errors.clear();

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          lang: this.appContentLanguage,
          per_page: 500,
        },
      ];

      const response = await this.$api.groupUserAttributes.list(...params);

      if (!response) return;

      const attributes = response.data.data.filter((el) => el.enabled);

      await this.$store.dispatch(
        "setGroupUserAttributeWizardAttributes",
        attributes,
      );
    },
    async listAttributesWithoutPlugin() {
      this.errors.clear();

      const params = [
        this.groupId,
        {
          group_plugin_id: "",
          lang: this.appContentLanguage,
          per_page: 500,
        },
      ];

      const response = await this.$api.groupUserAttributes.list(...params);

      if (!response) return;

      const attributes = response.data.data.filter((el) => el.enabled);

      await this.$store.dispatch(
        "setGroupUserAttributeWizardAttributesWithoutPlugin",
        attributes,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
